import toastr from 'toastr';

class ToastrService {

    constructor() {
        this.toastrOptions =  {
            "closeButton": true,
            "debug": false,
            "newestOnTop": false,
            "progressBar": false,
            "positionClass": "toast-bottom-right",
            "preventDuplicates": true,
            "showDuration": "300",
            "hideDuration": "1000",
            "timeOut": "4000",
            "extendedTimeOut": "1000",
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut"
        };
    }

    initialise(clickAction, timeout = null) {
        toastr.options = this.toastrOptions;
        if(timeout) {
            toastr.options.timeOut = timeout;
        }
        toastr.options.onclick = clickAction;
    }

    showInfo(title, message, clickAction) {     
        this.initialise(clickAction);
        toastr.info(message, title);
    };

    showWarning(title, message, clickAction) {     
        this.initialise(clickAction);
        toastr.warning(message, title);
    };

    showSuccess(title, message, clickAction) {     
        this.initialise(clickAction);
        toastr.success(message, title);
    };

    showError(title, message, clickAction) {     
        this.initialise(clickAction);
        if(message && message.startsWith('["')) {
            message = JSON.parse(message).join('<br />');
        }
        else if(message && message.startsWith('"')) {
            message = JSON.parse(message);
        }
        toastr.error(message, title);
    };
}
export default ToastrService;