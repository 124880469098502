import React, { useRef, useState } from 'react';
import SEO from '../components/seo';
import ContactService from '../services/contactService';
import ToastrService from '../services/toastrService';
import Recaptcha from 'react-recaptcha';

function Contact({ location }) {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [captcha, setCaptcha] = useState('');
    const [busy, setBusy] = useState(false);
    const recaptchaControl = useRef(null);

    const onCaptchaChange = (value) => {
        setCaptcha(value);
    }

    const onCaptchaExpired = () => {
        setCaptcha(null);
    }

    const submit = (event) => {
        event.preventDefault();
        if(captcha) {
            const toastrService = new ToastrService();
            setBusy(true);
            ContactService.sendMessage(name, email, message, captcha, (data, status) => {
                toastrService.showSuccess('', 'Thanks, we\'ll get back to you soon!');
                setName('');
                setEmail('');
                setMessage('');
                setCaptcha('');
                recaptchaControl.current.reset();
                setBusy(false);
            },
            (error) => {
                toastrService.showError('', 'Sorry, something went wrong! Please try again.');
                setCaptcha(null);
                recaptchaControl.current.reset();
                setBusy(false);
            });
        }
    }

    return (        
        <article className="page-content">
            <SEO title="Contact Us - Mike" url={location.pathname} />
            <header className="text-center">
                <h1>Contact Us</h1>
            </header>                
            <div className="page-content__body">            
                <div className="page-content__inner">   
                    <form action="" onSubmit={submit} className="form contact-form">
                        <div className="form-group">
                            <input className="form-control" type="text" placeholder="Name" required value={name} onChange={(event) => setName(event.target.value)} />
                        </div>
                        <div className="form-group">
                            <input className="form-control" type="email" placeholder="Email" required value={email} onChange={(event) => setEmail(event.target.value)} />
                        </div>
                        <div className="form-group">
                            <textarea className="form-control" placeholder="Message" required value={message} onChange={(event) => setMessage(event.target.value)} />
                        </div>
                        <Recaptcha
                            ref={recaptchaControl}
                            sitekey="6LfVssIZAAAAABtmBlF61bfXtIspe7jswdzpoiLj"
                            verifyCallback={onCaptchaChange}
                            expiredCallback={onCaptchaExpired}
                            theme="light" />
                        <div className="form-group form-group--footer">
                            <input className="btn btn-primary" type="submit" value="Send Message" disabled={busy} />
                        </div>
                    </form>                  
                </div>
            </div>
        </article>  
    );
}

export default Contact;