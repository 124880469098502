import urls from '../constants/urls.js';

class ContactService {

    static sendMessage (name, email, message, captcha, success, failure) { 
        return fetch(urls.API_URL + '/Contact', {
            method: 'POST',
            body: JSON.stringify({ name, email, message, captcha }),
            headers: {
              'Content-Type': 'application/json'
            }
        })
        .then(response => {
            if(response.ok) {
                return response.status !== 204 ? response.json() : ''
            }
            else {
                throw new Error('Network response was not ok');
            }
        })
        .then(data => {
            success(data);
        })
        .catch(error => {
            failure(error);
        });
    }
}
export default ContactService;